import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import reduxThunk from "redux-thunk";
import rootReducer from "./root-reducer";
import socketMiddleware from "./websocket/middleware";

// const middleware = [reduxThunk]

// const store = configureStore(rootReducer, compose(applyMiddleware(...middleware),
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()))

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(reduxThunk, socketMiddleware),
    devTools:
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
});

export const persistor = persistStore(store);
export default store;
