import types from './api.types';

export const initialState = {
    apiError: null
}

export const apiReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_API_ERROR:
            return { ...state, apiError: action.payload };
        case types.CLEAR_API_ERROR:
            return { ...state, apiError: null };
        default:
            return state;
    }
}

export default apiReducer;